import algosdk from "algosdk";
import MyAlgoConnect from "@randlabs/myalgo-connect";
import { PeraWalletConnect } from "@perawallet/connect";
import { DeflyWalletConnect } from "@blockshake/defly-connect";
import { psToken } from "./keys";
import { updateCDPs } from "../transactions/cdp";
import { ids } from "../transactions/ids";
import { VERSION } from "../globals";
// Partial fix from https://github.com/randlabs/myalgo-connect/issues/27
import buffer from "buffer";
const { Buffer } = buffer;
let nfd_updated = 0;
const peraWallet = new PeraWalletConnect();
const deflyWallet = new DeflyWalletConnect();

function sleep(seconds) {
  return new Promise((resolve) => setTimeout(resolve, 1000 * seconds));
}

function rerun(e) {
  if (e.toString().includes("Network request error. Received status 429")) {
    return true;
  }
  // TODO: We should eventually add more cases that return true here
  return false; // We can iterate on this as we identify cases where we don't want it to rerun
}

let _testnet = true;
if (VERSION == "MAINNET") {
  _testnet = false;
}
const testnet = _testnet;

// Basic setup
var activeWallet;
var activeWalletInfo;

// Sets up algosdk client
let _nodeServer = "https://testnet-api.algonode.cloud";
if (!testnet) {
  _nodeServer = "https://mainnet-api.algonode.cloud";
}
const nodeServer = _nodeServer;
export const algodClient = new algosdk.Algodv2("", nodeServer, "");

export async function accountInfo(address = null, retry = 0) {
  // XXX: Assumes the wallet is set
  // XXX: Could eventually cache for quicker/more effecient usage
  if (address == null) {
    address = activeWallet.address;
  }
  return algodClient
    .accountInformation(address)
    .do()
    .catch(async (e) => {
      if (rerun(e)) {
        await sleep(1 + retry);
        return await accountInfo(address, retry + 1);
      }
      throw e;
    });
}

export async function appInfo(appId, retry = 0) {
  return algodClient
    .getApplicationByID(appId)
    .do()
    .catch(async (e) => {
      if (rerun(e)) {
        await sleep(1 + retry);
        return await appInfo(appId, retry + 1);
      }
      throw e;
    });
}


async function getNFD(address) {
  const res = await fetch("https://api.nf.domains/nfd/address?address=" + address + "&limit=1&view=thumbnail");
  if (res.status != 404) {
    const entry = (await res.json())[0];
    console.log(entry);
    if (entry.caAlgo.find(i => i == address)) {
      return entry.name;
    }
  }
  return null;
}


async function updateNFD() {
  if ((Date.now() - nfd_updated)/1000 > 600){
    nfd_updated = Date.now();
  }
  else{
    return;
  }
  if (activeWallet.address) {
    const nfd = await getNFD(activeWallet.address);
    if (nfd) {
      activeWallet.name = nfd;
    }
  }
  return;
}


export async function updateWalletInfo() {
  let info = await accountInfo();
  activeWalletInfo = info;
  updateCDPs(activeWallet.address);
  let idx = -1;
  let promises = [];
  promises.push(updateNFD());
  for (let i = 0; i < info["assets"].length; i++) {
    const j = i;
    if (
      [ids.asa.gard, ids.asa.gain, ids.asa.gardian, ids.asa.galgo].includes(info["assets"][j]["asset-id"])
    ) {
      promises.push(
        algodClient
          .getAssetByID(info["assets"][j]["asset-id"])
          .do()
          .then((response) => {
            activeWalletInfo["assets"][j]["decimals"] =
              response["params"]["decimals"];
            activeWalletInfo["assets"][j]["name"] = response["params"]["name"];
            if (info["assets"][j]["asset-id"] == ids.asa.gard && j != 0) {
              idx = j;
            }
          }),
      );
    }
  }
  await Promise.allSettled(promises);
  if (idx != -1) {
    let temp = activeWalletInfo["assets"][0];
    activeWalletInfo["assets"][0] = activeWalletInfo["assets"][idx];
    activeWalletInfo["assets"][idx] = temp;
  }
  return activeWalletInfo;
}

// Sets up MyAlgoWallet
if (!window.Buffer) window.Buffer = Buffer; // Partial fix from https://github.com/randlabs/myalgo-connect/issues/27 XXX: THIS IS ALSO NEEDED FOR PERA TOO!!!
const myAlgoConnect = new MyAlgoConnect({ disableLedgerNano: false });

export function disconnectWallet() {
  if (peraWallet.isConnected) {
    peraWallet.disconnect();
  }
  activeWallet = undefined;
  activeWalletInfo = undefined;
  localStorage.removeItem("wallet");
  window.history.pushState({}, "", window.location.origin + "/");
  window.location.reload();
}


async function reconnect(conector) {
  await conector.reconnectSession(); // Ensures pera can reconnect
  conector.connector?.on("disconnect", disconnectWallet);
  await updateWalletInfo();
  return;
}


// Loading in the stored wallet
const storedWallet = localStorage.getItem("wallet");
if (!(storedWallet === null) && !(storedWallet === "undefined")) {
  activeWallet = JSON.parse(storedWallet);
  console.log(activeWallet);
  if (activeWallet.type == "Pera") {
    await reconnect(peraWallet);
  } else if (activeWallet.type == "Defly") {
    await reconnect(deflyWallet);
  } else if (activeWallet.type == "AlgorandWallet") {
    disconnectWallet(); // Old Pera Wallet - drop that
  } else if (activeWallet.type != "Exodus" || window.exodus.algorand.isConnected) {
    await updateWalletInfo(); // Could optimize by setting a promise and doing promise.all at the end of the page
  } else {
    disconnectWallet();
  }
}

// Sets up AlgoSigner
/*global AlgoSigner*/

export function getWallet() {
  // XXX: Returns even if active_address is not set
  // returns the active wallet
  // returns null if it is not set,
  // otherwise returns a dictionary formatted as
  // 		{"address": ADDRESS, "name": NAME, "type": TYPE}
  return activeWallet;
}

export function displayWallet() {
  if (getWallet()) {
    let res;
    if (getWallet().hasOwnProperty("name")) {
      res = getWallet().name;
    } else {
      res = getWallet().address;
    }
    if (res.length > 18) {
      res = res.slice(0, 15) + "...";
    }
    return res;
  }
}

export function getWalletInfo() {
  // returns dictionary formatted as
  /*
	{"address":"55JZKGYCKRDQYCBWUQGOSR23N7RG5UEU5H7YFSS7SPICURFDVJTTSGIN54","amount":34839186,"amount-without-pending-rewards":34839186,"apps-local-state":[],
	"apps-total-schema":{"num-byte-slice":0,"num-uint":0},
	"assets":[{"amount":1000000,"asset-id":73680809,"creator":"GTFE7VRKM4PA6K54OSW3QTXMBV5A7TURNAI7ZJPQVDEXKTBKB3MQ2T2ZLM","is-frozen":false}],
	"created-apps":[],"created-assets":[],"pending-rewards":0,
	"reward-base":27521,"rewards":0,"round":19979296,"status":"Offline"}
	*/

  // TODO: Could add a refresh option

  return activeWalletInfo;
}

export function getGARDInWallet() {
  let asset_array = activeWalletInfo.assets;
  for (var i = 0; i < asset_array.length; i++) {
    if (asset_array[i]["asset-id"] == ids.asa.gard) {
      return asset_array[i]["amount"];
    }
  }
  return 0;
}

function isiOS() {
  return (
    [
      "iPad Simulator",
      "iPhone Simulator",
      "iPod Simulator",
      "iPad",
      "iPhone",
      "iPod",
    ].includes(navigator.platform) ||
    (navigator.userAgent.includes("Mac") && "ontouchend" in document)
  );
}

async function connectHelper(connector, type) {
  let accounts;
  // Actually getting the connection
  try {
    accounts = await connector.connect();
  } catch(error) {
    if (error?.data?.type == "SESSION_CONNECT") {
      connector.disconnect();
      accounts = await connector.connect();
    } else {
      console.log(error?.data?.type);
      throw error; // TODO better error handling
    }
  }
  activeWallet = {};
  activeWallet.address = accounts[0];
  activeWallet.type = type;
  return;
}

export async function connectWallet(type) {
  // XXX: Only MyAlgoConnect should be used for testing other functionality at present
  // XXX: A future improvement would allow users to select a specific wallet based upon some displayed info, rather than limiting them to one
  let interval;
  switch (type) {
    case "MyAlgoConnect": {
      const settings = {
        shouldSelectOneAccount: true,
        openManager: false,
      };
      try {
        let wallets = await myAlgoConnect.connect(settings);
        activeWallet = wallets[0];
        activeWallet.type = "MyAlgoConnect";
      } catch (e) {
        // TODO: Handle errors gracefully
        // This would involve good UX informing the user that connection failed (and why)
      }
      break;
    }
    case "Exodus": {
      if (typeof window.exodus.algorand !== "undefined") {
        let exodus = window.exodus.algorand;
        try {
          let instance = await exodus.connect();
          exodus.on("disconnect", () => {
            // TODO: Need to refresh page
            console.log("DISCONNECT");
            disconnectWallet();
          });
          activeWallet = instance; // XXX: We need to add a way to select a specific account later
          activeWallet.type = "Exodus";
          // XXX: Does not set name
        } catch (e) {
          console.error(e);
          // TODO: Graceful error handling
        }
      } else {
        return {
          alert: true,
          text: "Exodus is not installed!",
        };
      }
      break;
    }
    case "AlgoSigner": {
      if (typeof AlgoSigner !== "undefined") {
        try {
          await AlgoSigner.connect();
          let ledger = "TestNet";
          if (!testnet) {
            ledger = "MainNet";
          }
          let accounts = await AlgoSigner.accounts({
            ledger: ledger,
          });
          activeWallet = accounts[0]; // XXX: We need to add a way to select a specific account later
          activeWallet.type = "AlgoSigner";
          // XXX: Does not set name
        } catch (e) {
          // TODO: Graceful error handling
        }
      } else {
        return {
          alert: true,
          text: "AlgoSigner is not installed!",
        };
      }
      break;
    }
    case "Pera": {
      await connectHelper(peraWallet, "Pera");
      break;
    }
    case "Defly": {
      await connectHelper(deflyWallet, "Defly");
      break;
    }
    default:
      // We should never get here, that's on bad programming
      console.error("Undefined wallet type!");
  }
  if (isiOS() && interval && activeWallet.address) {
    clearInterval(interval);
  }
  console.log(activeWallet);
  localStorage.setItem("wallet", JSON.stringify(activeWallet));
  return await updateWalletInfo();
}

export async function getParams(fee = 1000, flat = true) {
  // XXX: Could optimize via caching
  try {
    let params = await algodClient.getTransactionParams().do();
    params.fee = fee;
    params.flatFee = flat;
    return params;
  } catch (e) {
    if (rerun(e)) {
      await sleep(1);
      return await getParams(fee, flat);
    }
    throw e;
  }
}

function sameSender(sender1, sender2) {
  return JSON.stringify(sender1.publicKey) == JSON.stringify(sender2.publicKey);
}

function fixSet(senderAddressObj, txnarray, signed) {
  let res = [];
  let sIndex = 0;
  for (const [, txn] of txnarray.entries()) {
    if (sameSender(txn["from"], senderAddressObj)) {
      res.push(signed[sIndex]);
      sIndex++;
    } else {
      res.push(null);
    }
  }
  return res;
}

async function signSet(signer, senderAddressObj, txnarray) {
  // const senderAddressObj = algosdk.decodeAddress(info.address);
  const toSign = txnarray.filter((txn) =>
      sameSender(txn["from"], senderAddressObj),
    );
    const signed = await signer.signTransaction(
      toSign.map((txn) => txn.toByte()),
    );    
    return fixSet(senderAddressObj, txnarray, signed);
}

export async function signGroup(info, txnarray) {
  const senderAddressObj = algosdk.decodeAddress(info.address);
  switch (activeWallet.type) {
    case "Exodus": {
      const signedTxns = await signSet(window.exodus.algorand, senderAddressObj, txnarray);
      console.log(signedTxns);
      const parsedResults = signedTxns.map((element) => {
        return element
          ? { blob: new Uint8Array(Buffer.from(element, "base64")) }
          : null;
      });
      return parsedResults;
    }
    case "MyAlgoConnect": {
      return await signSet(myAlgoConnect, senderAddressObj, txnarray);
    }
    case "Defly":
    case "Pera": {
      const txnsToSign = txnarray.map((txn) => {
        if (!sameSender(txn["from"], senderAddressObj)) {
          return {
            txn: txn,
            message: "Transactions for the GARD system", // XXX: Eventually we could have a more informative string
            signers: [],
          };
        }
        return {
          txn: txn,
          message: "Transactions for the GARD system", // XXX: Eventually we could have a more informative string
        };
      });
      
      let result;
      if (activeWallet.type === "Pera") {
        result = await peraWallet.signTransaction([txnsToSign]);
      } else {
        result = await deflyWallet.signTransaction([txnsToSign]);
      }
      
      const signed = result.map((element) => {
        return element
          ? { blob: element }
          : null;
      });
      return fixSet(senderAddressObj, txnarray, signed);
    }
    case "AlgoSigner": {
      // Alogsigner requires all txns in a sign call to be from the same group,
      // 	so we have to split out groups
      console.log(txnarray);
      let groupIDs = [];
      txnarray.forEach((element) => {
        if (!groupIDs.includes(element.group.toString())) {
          groupIDs.push(element.group.toString());
        }
      });
      console.log(groupIDs);

      let signedTxns = [];
      // Now we sign each group
      for (const id of groupIDs) {
        const txsToSign = txnarray
          .filter((txn) => {
            return txn.group.toString() == id;
          })
          .map((txn) => {
            const encodedTxn = AlgoSigner.encoding.msgpackToBase64(
              txn.toByte(),
            );
            if (!sameSender(txn["from"], senderAddressObj)) {
              return {
                txn: encodedTxn,
                signers: [],
              };
            }
            return {
              txn: encodedTxn,
            };
          });
        console.log(txsToSign);
        signedTxns = signedTxns.concat(await AlgoSigner.signTxn(txsToSign));
      }
      console.log(signedTxns);
      const parsedResults = signedTxns.map((element) => {
        return element
          ? { blob: new Uint8Array(Buffer.from(element.blob, "base64")) }
          : null;
      });
      return parsedResults;
    }
    default:
      throw "No wallet selected!";
  }
}

let _explorer = "https://testnet.algoexplorer.io/tx/";
if (!testnet) {
  _explorer = "https://algoexplorer.io/tx/";
}
const explorer = _explorer;

async function sendRawTransaction(txn) {
  return algodClient
    .sendRawTransaction(txn)
    .do()
    .catch(async (e) => {
      if (rerun(e)) {
        await sleep(1);
        return await sendRawTransaction(txn);
      }
      throw e;
    });
}

export async function sendTxn(txn, confirmMessage = null, commitment = false) {
  // This works for both grouped and ungrouped txns
  // XXX: We may want a better flow later
  const tx = await sendRawTransaction(txn);
  console.log("Transaction sent: " + tx.txId);
  // We don't wait for this, but this does trigger a refresh after a transaction
  updateWalletInfo();
  let x = await algosdk.waitForConfirmation(algodClient, tx.txId, 10); // XXX: waitrounds is hardcoded to 10, may want to pick a better value
  if (confirmMessage) {
    console.log(x);
    if (!commitment) {
      return {
        alert: true,
        text:
          confirmMessage +
          "\nTransaction ID: <a href=\"" +
          explorer +
          tx.txId +
          "\">" +
          tx.txId.substring(0, 15) +
          "...</a>" +
          "\nConfirmed in round: " +
          x["confirmed-round"],
        txn: x,
      };
    } else {
      return {
        alert: true,
        text: confirmMessage + "Transaction ID: " + tx.txId,
        txn: x,
      };
    }
  }
  return x;
}

export function handleTxError(e, text) {
  // Supresses basic universal errors

  // User cancels TX
  if (e.toString() == "Error: Operation cancelled") {
    return;
  }
  console.error(e);
  alert(text + ": \n" + e);
}

export async function getAppByID(id) {
  return algodClient
    .getApplicationByID(id)
    .do()
    .catch(async (e) => {
      if (rerun(e)) {
        await sleep(1);
        return await getAppByID(id);
      }
      throw e;
    });
}

// Future improvement - cache names of wallets
