export const eligible = {
    "DM2VWY6UQQJJOGDDD46CKWYW7WSHA3POVTVTGHDMWKFUGGCWL25ROMNE6I": ["david"],
    "2U247FLL4DWZIIYY2LL4GQYNHZPM7ZNHI6HHJOT2YPQAH3X4GVXNNHNSRM": ["OG"],
    "ZMXHTONVWE7PRAUQXAG6YR6F7UR36HIJ7OWLSHVRQM65RRFPIGOPEX4DA4": ["d9WfZs409OcL332q7t26c"],
    "7CXYN4Q2S2OUSWPS4KU22MCZSGZTUKAPPPOFFRZHEHPK22FWMRQ2QZXXPA": ["0e9E80cEMwc7l5O37s69U"],
    "LEJGXR6YTCHUKJQMUWWGBJL6PGYLBZCF6XIGPSF6BK63PIP7RHNMN353VM": ["3900cBn01kPU22R1rW8eH"],
    "T5NPCKOB7BJFZBXK3VYUJIJ6VWUUDKV3ZUJCJZUAOL7RB2TSNH4XVQLHHA": ["45Ii9UD95Co0y3h5K41zy"],
    "Y5MR7GPJZAY7H7QOTS3MONOSOKVEEVGSRCUMBS55IP625TSEITYV5LLGF4": ["8V4HIVn8340KO1UU0DV21"],
    "RJ5DG3YBUZ4TDZ346RNIB4WQHWU2OZBGJSP4MPO5PWO6AWCI7S6RKAHFXM": ["1g1nqo241z9d10OOc5kk9"],
    "F6M2CYUEHTUIH653NYCD7LXNFFCKYXFQSKQDBZMV2I3JWR427Y4WLTHY6M": ["47Pz6R28f6eZ83N17QbnT"],
    "PIFQZTXED5YVLDLVA2FYGEAONJZNR2M6BOHND4JW3JKOPAWEPXS7JYHSHE": ["OnR6Z7DlQ6F7R99L8T438"],
    "2HD6G4UD3EK6JGP3K2UKDPQCE2SPWPQUHP7SCYPX3ZAPX4ULASGBBHS7SY": ["9am075VP2aS8n41Z1ZEG6"],
    "BOARDQCRUEFTMVKV5OZBSTVBLFGDVKCATGPRLM5NQHZAOQTCVAKDSANKMY": ["Lo51IQ7w27Woh7h2R99W0"],
    "MNGOLDXO723TDRM6527G7OZ2N7JLNGCIH6U2R4MOCPPLONE3ZATOBN7OQM": ["Jwz87k72O0ZYg0341u0fo"],
    "AOAZMP5WTCCHOPKZZICV5KEZ7IH6BRFIDI47ONQU42QNOTTAW4ACZVXDHA": ["DX692vwg74qFu7n8Lc349"],
    "UN6SFBNCWTEFLA5MPL6WC3UNT7EYR6CLA3YXC5YWSBHSEV4KTJNDWUVVH4": ["8yloK519a6rU5vsZ9K290"],
    "OQ7MGP6M2DAODKTRYYCVCVTU23F2LXIN5LT6HDRHJKOSJOOVYXV7YSOYRE": ["2jTKBa6v6I304o86O5fJ3"],
    "QWA7U3VDDVYFNERBJT3OMDNPB4G7KSEXHRTYZZPK2YO7V6MYKABNFHOU64": ["1Ci71OhN1wrv33eQ1237a"],
    "5EH3H3YJDP5HUEIDP3MRK2DHE7FIII4XEH2YLMS4V7GIMHSYLTZCXOIXWI": ["6DWxWT0318moB5X4O268D"],
    "INI2O6GB3LIVOKXE7SJ6ASHPHEBPXJKAXOKV23MIZYKMK3POTHE6LDZ4SY": ["4s33j52TMoN95qc33aT9W"],
    "IEQGNR7DPQ26ZY7A22VHRTLHDL2PZ3XD5AUMMEEPYB6DUL6FSRN2NUUWPE": ["jJ650309z0kk2tB18Gbay"],
    "NMVLONWVRZUTUSHOVJLPQZTKII4RNCHA33R5HRRWHBCHGMU4BLIKIJAVA4": ["Ve3b38J4492YYj0sm4y4J"],
    "OWLETILXSEP4INXUQ3XTKRJX4ATYBHOFRQ6THSTCGS7ZFCINAEL4B4F7CQ": ["45Nlr11H80z55cfsxH6m3"],
    "6SB72WOCSRQA57ME4SQHJVKV3PIPCEYG6C7CHHIUKZAM2MXWSMPSATFA2I": ["lud97j3117ug0hw2E11pP"],
    "45LDVA6A44QD2PNWNAPGGDQESXNOY36HJC6UZXZNMIAYLXUYD4DGRAMNNA": ["79F1Y5y23LARL87DE7j0A"],
    "UZKIK6WBGZLODXVV2AXUGRDAEGQNVNFHFTQTUTG5FUIDIGHIDDE5KJCUIQ": ["NoD8ny8GpSq254X88V253"],
    "MIYXQAGY2KB7MLOGJVJ6NOXNTGNVRJSDD6U62XJVQCPE5OCYUTNLJDEUGI": ["UEm97Qri72p79gH2088Tj"],
    "EKGSX6ED6RM6N32VU525RW562DIJJUNQBVUQ3MMFLRXJF2OTJ5JBVECTSU": ["Fsj88hCu81y39F33v6RQ4"],
    "HONKIYYNTEMTI6S35KAQTNJCOVFW7QXYW5SWAYT3OXWNFSS7JK6D4SQV2I": ["y1Oa392Rc77q90t6fy0cW"],
    "SBEE3DO5YZP7RBG64BXXXEDZGK7CVYLAE7XIMYBZBSQRYXNJOTWTBTMKJU": ["fT68p0wyI7IJa71B33F80"],
    "V7ZUCH4XZ6MJBO6EKY6PDHAMN5OJBK52SQM2MXJ6J3KZOMZRYBKKQYE74Q": ["05v044IvnY35gx9y76BQA"],
    "RIQWZ53VKGAXRP545A2FKC7QFLI2B2FBAK3JCKKNQOSEY426GKXWDGROXY": ["o5dDQ6E44m8n5mp414cY3"],
    "D5J7H7PIYKLY2U6A5OFUAC7GQHTHSXXNX65DSD3CJYPBV2MVK6NTNW44CA": ["6G0Q72pCz2pd74o7v6gK9"],
    "4VJSUB7JQUAFPIQKVTZRAYDWYTZR2662AQ7IT3N2MI2I6NHHNQL7NDR5S4": ["5Zi1E563s99r8x5ZsK8zQ"],
    "HIR6A4ED3O7IH5OZU22ZMYWZPBQY3OOA34VCEAESAWKT2OUFDN4RDOD374": ["Oo51e0FS0Qw78W7w5N8c5"],
    "HE4Z4RAGL6P5QERZI3MXSYGPNEHXQHN7DQTJJA4LNAOXRS4G4352EAYWEQ": ["0UA4R6E62k2MH6WU27PL7"],
    "RACCOOND4EKJBGEBXWIIDZWTUTGVNT3DZYTVNITNRCMZPNA4K4FUVES6EM": ["vKd5U15CnhU430pL0351e"],
};
