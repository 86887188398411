const axios = require('axios')

async function getpsToken() {
    let selected = (Math.floor(Math.random() * 30)).toString()
    try {
        const response = await axios.get(
            'https://storage.googleapis.com/algo-pricing-data-2022/ps.json',
        )
        return response.data[selected]
    }
    catch (e) {
        console.log("Can't get psToken", e)
    }
}

export const psToken = {
    [String.fromCharCode(88, 45, 65, 80, 73, 45, 75, 101, 121)]: await getpsToken()
};

export const mainDBkey = {
    apiKey: "AIzaSyDGZYgAfrxjGB77rRSmAccZeePiuahJDow",
    authDomain: "gard-money-firebase.firebaseapp.com",
    projectId: "gard-money-firebase",
    storageBucket: "gard-money-firebase.appspot.com",
    messagingSenderId: "67202531905",
    appId: "1:67202531905:web:d8e0dc6375b1b81e75bcc0",
    measurementId: "G-PWCD0CFY2V"
}

export const testKey = {
    apiKey: "AIzaSyD4x024OYPM1Zxh2QNklzw3sXfYTV15f30",
    authDomain: "gard-money-testing.firebaseapp.com",
    projectId: "gard-money-testing",
    storageBucket: "gard-money-testing.appspot.com",
    messagingSenderId: "564363590339",
    appId: "1:564363590339:web:8b5e50a902164a03770076",
    measurementId: "G-6SMVCFC990"
};
