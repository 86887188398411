module.exports = {
    "Account Info": "Account Info",
    "ALGO APR" : "The annual interest rate (in Algo) from participating in Chain governance",
    "ALGO Governance APR": "he current APR ALGO governors will receive for participating in governance through the GARD protocol",
    "Bonus Supply Rewards": "At some points in time the GARD Protocol will supply bonus rewards to users who are supplying assets to be used as collateral",
    "Borrow APR": "Estimated APR for borrowing",
    "Already Borrowed": "Total GARD minted in this CDP",
    "Already Supplied": "Total asset offered as collateral in this CDP",
    "Borrow Limit" : "Maximum GARD you can borrow",
    "Borrow Rewards" : "Estimated rewards for borrowing",
    "Borrow Utilization": "How much of your maximum borrow you've used up so far",
    "Circulating GARD" : "Minted GARD less GARD staked natively",
    "Collateral" : "Crypto locked in escrow to pay off debt if necessary",
    "Collateral Factor": "The maximum amount of GARD you can borrow as a function of the value of the collateral you have supplied",
    "Est. Rewards / Day": "The expected increase in 'Your Stake' in a 24-hour period, given current incentives, pool revenues, and pool usage.",
    "Exchange Rate" : "The units of asset A required to exchange for one unit of asset B.",
    "Fees Received" : "Cumulative GARD Protocol Fees",
    "Fee Rate" : "The fee charged by the pool is represented as a percent of the value exchanged.",
    "GARD System Collateralization" : "The ratio of locked collateral value to minted GARD value",
    "GARD Borrow APR": "The cost to borrow GARD each year denominated in GARD",
    "GARD Governors": "Number of unique addresses that have committed to governance via GARD protocol",
    "GARD Governance APR" : "Annual percentage yield recieved from participating in governance (excluding DeFi bonus)",
    "GARD Staking APR" : "The current annual return (in GARD) given current borrows, stakes, and incentives",
    "Liquidation Price" : "Price point at which you loan will be liquidated",
    "Collateralization Ratio" : "Ratio must be above 110%",
    "Liquidity Fee": "Fee from change to pool liquidity",
    "Minimum Received" : "The minimum amount of the asset you receive that you’re swapping into. This value is a function of your slippage tolerance, price impact, and other trades potentially happening simultaneously.",
    "New Rewards": "Rewards earned since last pool transaction",
    "No-Lock GARD APR" : "Annual interest rate (in GARD) paid to users staked in native no-lock pool.",
    "Number of Users":"The number of protocol users is defined as the number of unique Algorand accounts currently borrowing or staking with GARD",
    "Price Impact": "Percentage asset price will change as a result of this swap",
    "Slippage Tolerance": "Amount to adjust for price change between user input and transaction submission. Set at 0.05%",
    "Stability Fee" : "A v1 protocol fee",
    "Staked GARD" : "The amount of GARD earning interest in native staking pools",
    "Staking APY":"The current yearly return expected to be earned via no-lock staking given the current participation and reward rate is subject to change",
    "Supply APY" : "Estimated APY for supplying",
    "Supply Rewards" : "Estimated Rewards for supplying",
    "Supply Limit" : "Maximum ALGOs you can supply",
    "Total GARD Borrowed": "The total value of GARD that has been borrowed against the valuable assets deposited in the protocol's non-custodial smart contracts denominated in dollars",
    "Total Supplied (Asset)": "The amount of the asset you have supplied so far",
    "Total Supplied ($)": "The total value in USD of the assets you’ve supplied",
    "Total Value Locked (TVL)" : "Overall value of crypto assets deposited into the GARD Protocol system",
    "Total Value Locked" : "Overall value of crypto assets deposited into the GARD Protocol system",
    "Total Committed" : "Amount of ALGOs from CDPs that are currently commited to governance",
    "Volume" : "Number of GARD exchanged in the past week",
    "Your Stake" : "Number of tokens earning rewards in the pool. Also, number of tokens entitled to withdraw.",
    "Live Auctions" : "When another user’s collateral approaches the value of their debt, it is sold at auction to pay off their debt. You may purchase Algos/gAlgos with GARD at a discount.",
    "System Metrics" : "Measurements of TVL, Staked GARD, and Circulating GARD in GARD ecosystem"
};
